<template>
  <div v-if="firstUnsubmittedSurveys?.length">
    <p class="text-secondary-900 text-base mb-5 font-anomaliaRegular">
      השאלונים שלי
    </p>
    <BaseWidget
      v-for="survey in firstUnsubmittedSurveys"
      :key="survey?.id"
      :container="false">
      <template #default>
        <div class="flex justify-start items-center">
          <component
            :is="getIcon(survey?.questionnaire.display.themeColor)"
            class="w-20"/>
          <div class="pr-5">
            <p class="text-default text-lg font-anomaliaBold">
              שאלון {{ survey.questionnaire.title }}
            </p>
            <a
              :href="getSurveyRoute(survey)"
              target="_blank"
              rel="noopener"
              class="font-anomaliaLight text-primary-500 cursor-pointer">
              כניסה לשאלון>
            </a>
          </div>
        </div>
      </template>
    </BaseWidget>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { BaseWidget } from '@nwire/amit-design-system';
import { useAccountStore } from '/@/store/account';
import { useRouter } from 'vue-router';
import { getSurveyEnrollments } from '/@/services/surveys';
import PurplePlanet from '/@/assets/purple-planet.svg?component';
import BrownPlanet from '/@/assets/brown-planet.svg?component';
import PinkPlanet from '/@/assets/pink-planet.svg?component';
import BluePlanet from '/@/assets/blue-planet.svg?component';
import GreenPlanet from '/@/assets/green_planet.svg?component';
import DarkBrownPlanet from '/@/assets/dark_brown_planet.svg?component';
const accountStore = useAccountStore();

const router = useRouter();

const enrollments = ref<any[]>([]);
const firstUnsubmittedSurveys = ref<any[]>([]);

const getSurveyRoute = survey => {
  return router.resolve({
    name: 'survey-preview',
    params: {
      survey: survey.survey.id,
      questionnaire: survey.questionnaire.id,
    },
  }).href;
};

const getIcon = theme => {
  switch (theme) {
    case 'red':
      return PurplePlanet;
    case 'blue':
      return PinkPlanet;
    case 'yellow':
      return BluePlanet;
    case 'sky-blue':
      return BrownPlanet;
    case 'green':
      return GreenPlanet;
    case 'brown':
      return DarkBrownPlanet;
  }
};
onMounted(() => {
  accountStore.groups
    .filter(group => group.surveys)
    .flatMap(group => group.surveys)
    .map(survey => {
      const { onError, onSuccess } = getSurveyEnrollments(
        survey.id,
        accountStore.user.id,
      );
      onSuccess(response => {
        if (!response.data.length) return;
        enrollments.value = [...enrollments.value, response.data[0]];
        firstUnsubmittedSurveys.value = enrollments.value.flatMap(
          enrollment => {
            const pendingEnrollment = enrollment?.summary.find(
              element => element.status === 'pending' && !element.locked,
            );
            if (pendingEnrollment)
              return {
                ...pendingEnrollment,
                survey: enrollment.survey,
              };
            return [];
          },
        );
      });
      onError(response => {
        console.log('error response', response);
      });
    });
});
</script>
