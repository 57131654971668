<template>
  <div class="flex flex-col h-full p-10 overflow-x-hidden">
    <div class="flex flex-col grow">
      <div class="flex flex-col flex-grow">
        <span class="text-dark font-simplerBold text-xl">
          {{ t('profile.title') }}
        </span>
        <div class="flex flex-wrap gap-10 py-4">
          <div
            v-for="(image, index) in userGender"
            :key="index"
            class="rounded-3xl cursor-pointer">
            <img
              :class="
                accountStore.currentIndexImage === index &&
                'scale-90 border-5 rounded-full border-primary'
              "
              :src="image"
              alt="avatar"
              class="w-10 h-10 lg:w-20 lg:h-20"
              @click="checkIndex(index, image)"/>
          </div>
        </div>
      </div>
    </div>
    <base-button
      class="flex items-center justify-center lg:self-end lg:ml-12 lg:w-30 my-6 h-12"
      :disabled="!isButtonDisabled"
      @click="setAvatar()">
      <span class="text-white">{{ t('course.choose') }}</span>
    </base-button>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAccountStore } from '/@/store/account';
import saveAvatar from '/@/services/students';
import { avatars } from '/@/data/static_data';
import { storeToRefs } from 'pinia';
import { get } from 'lodash';
import { BaseButton } from '@nwire/amit-design-system';
import emitter from '@amit/composables/src/useBus';

const { t } = useI18n();
const accountStore = useAccountStore();
const { user } = storeToRefs(accountStore);
const isButtonDisabled = ref(false);
const selectedAvatar = ref('');

const userGender = computed(() => {
  const gender = get(accountStore.user, 'gender', '');
  return avatars[gender] ?? [...avatars.male, ...avatars.female];
});

const setAvatar = () => {
  const { onSuccess } = saveAvatar(accountStore.user.id, selectedAvatar.value);
  onSuccess(() => {
    user.value.avatar = selectedAvatar.value;
    emitter.emit('closeAvatarsModal');
  });
};

const checkIndex = (index, currentImg) => {
  selectedAvatar.value = currentImg;
  if (accountStore.currentIndexImage === index) {
    accountStore.setIndex(-1);
  }
  isButtonDisabled.value = true;
  accountStore.setIndex(index);
};
</script>
