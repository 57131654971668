<template>
  <BaseModal
    v-show="showModalProfile"
    :size="'5xl'"
    @close="showModalProfile = false">
    <template #body>
      <StudentProfile v-show="showModalProfile" />
    </template>
  </BaseModal>
  <div class="flex flex-col h-full gap-2">
    <div
      class="flex w-full text-xl md:text-2xl font-anomaliaLight text-secondary-900 pb-7 relative">
      {{ greeting }}
      <GirlDashboard class="hidden pt-16 absolute top-0 left-0" />
    </div>
    <InfoTabs :info="info" :selectable="false" />
    <div class="text-sm font-simplerBold my-3 text-secondary-900">
      קורסים בלמידה
    </div>
    <div class="flex flex-1">
      <ScrollableContainer hidescrollbar>
        <div v-if="accountStore.groups.length && coursesEnrolled.length">
          <div class="grid grid-cols-1 xs:grid-cols-2 gap-3 m-1.5">
            <div v-for="enrollment in coursesEnrolled" :key="enrollment.id">
              <CourseCard
                type="student"
                :course="enrollment.course"
                :progress="{
                  total_progress: enrollment.total_progress,
                  summary: enrollment.summary,
                  current_lesson: enrollment.current_lesson,
                }"
                :group="enrollment.group"/>
            </div>
          </div>
        </div>
        <div
          v-else-if="!coursesEnrolled.length && inLoadingTimeRange"
          class="grid grid-cols-1 xs:grid-cols-2 gap-3">
          <CourseCard
            v-for="(course, index) in Array.from(new Array(8))"
            :key="index"
            type="library"
            :loading="true"
            :course="null"/>
        </div>
        <div
          v-else
          class="flex items-center justify-center font-anomaliaLight md:text-xl py-20">
          <div class="flex flex-col text-center">
            <span>{{ $t('home.no_courses') }}</span>
            <span class="text-primary">{{ noCoursesText }}</span>
          </div>
        </div>
      </ScrollableContainer>
    </div>
  </div>

  <SideWidget>
    <NotificationWidget />
  </SideWidget>
  <SideWidget>
    <SurveysWidget />
  </SideWidget>
</template>
<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { getGreeting } from '/@/composables/useDashboard';
import { useAccountStore } from '/@/store/account';
import { useNotificationStore } from '/@/store/notifications';
import NotificationWidget from '/@/components/NotificationWidget.vue';
import { BaseModal } from '@nwire/amit-design-system';
import StudentProfile from '/@/components/StudentProfile.vue';
import { CourseCard } from '@amit/components';
import emitter from '@amit/composables/src/useBus';
import SurveysWidget from '/@/components/SurveysWidget.vue';
import { SideWidget } from '@amit/layout';
import GirlDashboard from '/@/assets/girlDashboard.svg?component';
import OpenBook from '../assets/bookOpen.svg?url';
import NewMessages from '/@/assets/newMessages.svg?url';
import CheckCircle from '/@/assets/CheckCircle.svg?url';
import GraduationHatOutline from '/@/assets/graduationHatOutline.svg?url';
import InfoTabs from '/@/components/InfoTabs.vue';
import ScrollableContainer from '/@/components/ScrollableContainer.vue';
import { useI18n } from 'vue-i18n';

const accountStore = useAccountStore();
const notificationsStore = useNotificationStore();
const { t } = useI18n();

const greeting = computed(() =>
  accountStore.user.id ? `${getGreeting()} ${accountStore.user.fullName}!` : '',
);
const showModalProfile = ref(false);
const inLoadingTimeRange = ref(true);

const isStudent = computed(() => accountStore.user.role.includes('student'));
const noCoursesText = computed(() => {
  if (isStudent.value) {
    return t('home.check_with_teacher');
  }
  return t('home.register_via_library');
});

emitter.on('openAvatarModal', () => {
  showModalProfile.value = true;
});

emitter.on('closeAvatarsModal', () => {
  showModalProfile.value = false;
});

onMounted(() => {
  accountStore.setEnrollments();
  if (accountStore.user && !accountStore.user.avatar && isStudent.value) {
    showModalProfile.value = true;
  }

  setTimeout(() => {
    inLoadingTimeRange.value = false;
  }, 5000);
});
const coursesEnrolled = computed(() => {
  const courses = accountStore.enrollments || [];
  return courses.flatMap(course => {
    const group = getGroupById(course.group);
    if (!group) {
      return [];
    }

    return {
      ...course,
      group,
    };
  });
});

const getGroupById = (id: string) => {
  return accountStore.groups.find(group => group.id === id);
};

const info = computed(() => [
  {
    id: 'ongoing-courses',
    title: 'קורסים בלמידה',
    value: coursesEnrolled.value?.filter(c => c.total_progress < 100).length,
    icon: OpenBook,
    label: 'קורסים',
    color: 'bg-green-100',
    colorSelected: 'bg-green-200',
  },
  {
    id: 'completed-courses',
    title: 'קורסים שהושלמו',
    value: coursesEnrolled.value?.filter(c => c.total_progress === 100).length,
    icon: CheckCircle,
    label: 'קורסים',
    color: 'bg-red-100',
    colorSelected: 'bg-red-200',
  },
  {
    id: 'feedback',
    title: 'משוב מהמורה',
    value: notificationsStore.getUnreadFeedbackNotifications.length,
    icon: NewMessages,
    label: 'הודעות',
    color: 'bg-cyan-gentle-100',
    colorSelected: 'bg-cyan-gentle-200',
  },
  {
    id: 'grades',
    title: 'ציונים חדשים',
    value: 0,
    icon: GraduationHatOutline,
    label: 'ציונים',
    color: 'bg-purple-100',
    colorSelected: 'bg-purple-200',
  },
]);
</script>
