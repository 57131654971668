const BASE_AVATAR_URL =
  'https://res.cloudinary.com/dcodkxpej/image/upload/f_auto,q_auto,c_fill,h_200,w_200/';

export const avatars = {
  male: [
    BASE_AVATAR_URL +
      'v1673949396/Amit/Avatars/New%20boys%20avatar/Group_36307_q32vlq.svg',
    BASE_AVATAR_URL +
      'v1673949393/Amit/Avatars/New%20boys%20avatar/Group_36304_cfelqt.svg',
    BASE_AVATAR_URL +
      'v1673949387/Amit/Avatars/New%20boys%20avatar/Group_36306_zvd1nx.svg',
    BASE_AVATAR_URL +
      'v1673949376/Amit/Avatars/New%20boys%20avatar/Group_36300_ztreka.svg',
    BASE_AVATAR_URL +
      'v1673949374/Amit/Avatars/New%20boys%20avatar/Group_36301_dhnkij.svg',
    BASE_AVATAR_URL +
      'v1673949373/Amit/Avatars/New%20boys%20avatar/Group_36303_ivqs4m.svg',
    BASE_AVATAR_URL +
      'v1673949370/Amit/Avatars/New%20boys%20avatar/Group_36302_pozv0l.svg',
    BASE_AVATAR_URL +
      'v1673949363/Amit/Avatars/New%20boys%20avatar/avatar_0-2_t2vpdv.svg',
    BASE_AVATAR_URL +
      'v1673949360/Amit/Avatars/New%20boys%20avatar/avatar_0-3_h36tkz.svg',
    BASE_AVATAR_URL +
      'v1673949360/Amit/Avatars/New%20boys%20avatar/27470336_7294793_iahmsv.svg',
    BASE_AVATAR_URL +
      'v1673949353/Amit/Avatars/New%20boys%20avatar/avatar_0-1_r8msne.svg',
    BASE_AVATAR_URL +
      'v1673949362/Amit/Avatars/New%20boys%20avatar/avatar_0_tritom.svg',
  ],
  female: [
    BASE_AVATAR_URL + 'v1672660850/Amit/Avatars/avatar_0-7_neqp3o.svg',
    BASE_AVATAR_URL + 'v1672660848/Amit/Avatars/avatar_0-10_geljvo.svg',
    BASE_AVATAR_URL + 'v1672660845/Amit/Avatars/avatar_0-11_t7v1qu.svg',
    BASE_AVATAR_URL + 'v1672660845/Amit/Avatars/avatar_0_nbjryk.svg',
    BASE_AVATAR_URL + 'v1672660844/Amit/Avatars/avatar_0-9_bsodf3.svg',
    BASE_AVATAR_URL + 'v1672660843/Amit/Avatars/avatar_0-5_s6lnvd.svg',
    BASE_AVATAR_URL + 'v1672660840/Amit/Avatars/avatar_0-3_x1cug0.svg',
    BASE_AVATAR_URL + 'v1672660840/Amit/Avatars/avatar_0-8_q1gfzz.svg',
    BASE_AVATAR_URL + 'v1672660838/Amit/Avatars/avatar_0-1_sm3fi6.svg',
    BASE_AVATAR_URL + 'v1672660837/Amit/Avatars/avatar_0-4_xfvmpq.svg',
    BASE_AVATAR_URL + 'v1672660835/Amit/Avatars/avatar_0-2_msh472.svg',
    BASE_AVATAR_URL + 'v1672660835/Amit/Avatars/avatar_0-6_wipzaa.svg',
  ],
};
