import { useI18n } from 'vue-i18n';

export const getGreeting = () => {
  const { t } = useI18n();

  const currentTime = new Date().getHours();
  if (currentTime <= 12) {
    return t('home.goodMorning');
  }
  if (currentTime <= 15) {
    return t('home.goodNoon');
  }
  if (currentTime <= 17) {
    return t('home.goodAfterNoon');
  }
  if (currentTime <= 22) {
    return t('home.arabTov');
  }
  return t('home.goodNight');
};
