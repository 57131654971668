<template>
  <div class="flex w-full px-6 py-4 bg-white rounded-lg gap-3">
    <div
      v-for="(
        { id, title, value, label, icon, color, colorSelected, onClick }, index
      ) in info"
      :key="index"
      class="flex flex-1 gap-3">
      <div
        class="flex flex-1 items-center gap-2 rounded-4 p-2"
        :class="{
          'bg-secondary-75': clicked === id,
          'hover:bg-secondary-75 cursor-pointer': selectable,
        }"
        @click="
          () => {
            clicked && selectable && (clicked = id) && onClick?.(id);
          }
        ">
        <span
          class="p-3 rounded-lg"
          :class="clicked === id ? colorSelected : color">
          <img :src="icon" alt="icon" />
        </span>
        <div class="flex flex-col gap-1">
          <span
            class="font-simplerRegular text-secondary-800 text-sm"
            :class="{ 'cursor-pointer': selectable }">
            {{ title }}
          </span>
          <span
            class="font-simplerRegular text-secondary-900 font-bold text-sm"
            :class="{ 'cursor-pointer': selectable }">
            {{ value === 0 ? 'אין' : value }} {{ label }}
          </span>
        </div>
      </div>
      <Divider v-if="index < info.length - 1" layout="vertical" class="my-2" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { type PropType, ref } from 'vue';
import Divider from 'primevue/divider';

defineProps({
  info: {
    type: Array as PropType<
      Array<{
        id: string;
        title: string;
        value: number;
        label: string;
        icon: string;
        color: string;
        colorSelected: string;
        onClick?: (id: string) => void;
      }>
    >,
    required: true,
  },
  selectable: {
    type: Boolean,
    default: false,
  },
});

const clicked = ref<string | null>(null);
</script>
